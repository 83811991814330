import { ArtList } from '../components';

export const SculpturesPage = () => {
  return (
    <>

      <ArtList category='esculturas' />

    </>
  )
}
