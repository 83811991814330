import {ArtList} from '../components';

export const MuralsPage = () => {

  return (
      <>
        <ArtList category='murales' />
      </>
  )
}
