import { ArtList } from '../components';


export const PaintingsPage = () => {
  return (
    <>
      <ArtList category='pinturas' />
    </>
  )
}
