import { AppRouter } from './router/AppRouter';


export const JulietArtsApp = () => {
  return (
    <>
        <AppRouter />
    </>
  )
}
